import { StorefrontApiClient } from "@shopify/storefront-api-client";
import { title } from "process";

const CART_FRAGMENT = `id
        checkoutUrl
        lines(first: 10) {
          edges {
            node {
              id
              quantity
              cost {
                subtotalAmount {
                  amount
                }
                totalAmount {
                  amount
                }
                amountPerQuantity {
                  amount
                }
                compareAtAmountPerQuantity {
                  amount
                  currencyCode
                }
              }
              discountAllocations {
                discountedAmount {
                  amount
                }
                ... on CartAutomaticDiscountAllocation {
                  __typename
                  title
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  product {
                   title
                  }
                   price {
                    amount
                    currencyCode
                  }
                  image {
                    altText
                    height
                    id
                    width
                    url
                  }
                }
              }
            }
          }
        }
         cost {
     subtotalAmount {
        amount
        currencyCode
      }
      checkoutChargeAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
        # any other cart object fields`;

const createCartMutation = `mutation createCart($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      cart {
        ${CART_FRAGMENT}
      }
    }
  }`;

const getCartQuery = `query getCartQuery($cartId: ID!) {
  cart(id: $cartId) {
    ${CART_FRAGMENT}
  }
}`;

const addLineItemsMutation = `mutation AddLineItemsToCart($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ${CART_FRAGMENT}
    }
    userErrors {
      field
      message
    }
  }
}`;

const removeLineItemsMutation = `mutation removeLineItemsMutation($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
       ${CART_FRAGMENT}
    }
  }
}`;

const selectProductQuery = `query selectProductQuery($productId: ID!) { 
  product(id: $productId) {
    id
    title
    variants(first: 100) {
      edges {
        node {
          id
          title
          availableForSale
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
}`;

const lineItemsUpdateMutation = `mutation CartLineItemsUpdate($cartId: ID!, $lineItems: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lineItems) {
    cart {
     ${CART_FRAGMENT}
    }
    userErrors {
      field
      message
    }
  }
}`;

export type Cart = {
  id: string;
  lineItems: any[];
  checkoutUrl: string;
  cost: {
    subtotalAmount: {
      amount: number;
      currencyCode: string;
    };
    checkoutChargeAmount: {
      amount: number;
      currencyCode: string;
    };
    totalAmount: {
      amount: number;
      currencyCode: string;
    };
  };
};

export type LineItemInput = {
  variantId: string; //for my backwaredds compatibility
  quantity: number;
  attributes?: { key: string; value: string }[];
};

export type LineItemUpdate = {
  id: string;
  quantity: number;
  attributes?: { key: string; value: string }[];
};

export async function createCart(client: StorefrontApiClient): Promise<Cart> {
  const { data, errors } = await client.request(createCartMutation, {
    variables: {
      cartInput: {},
    },
  });
  console.log("api res", data, errors);
  return {
    id: data.cartCreate.cart.id,
    lineItems: mapCartLines(data.cartCreate.cart.lines),
    checkoutUrl: data.cartCreate.cart.checkoutUrl,
  } as Cart;
}

export async function getCart(
  client: StorefrontApiClient,
  cartId: string
): Promise<Cart> {
  const { data, errors } = await client.request(getCartQuery, {
    variables: { cartId },
  });
  console.log("get cart res", data, errors);
  return {
    id: data.cart.id,
    lineItems: mapCartLines(data.cart.lines),
    checkoutUrl: data.cart.checkoutUrl,
    cost: data.cart.cost,
  } as Cart;
}

export async function removeLineItemsFromCart(
  client: StorefrontApiClient,
  cartId: string,
  lineIds: string[]
): Promise<Cart> {
  const { data, errors } = await client.request(removeLineItemsMutation, {
    variables: { cartId, lineIds },
  });
  console.log("remove line items res", data, errors);
  return {
    id: data.cartLinesRemove.cart.id,
    lineItems: mapCartLines(data.cartLinesRemove.cart.lines),
    checkoutUrl: data.cartLinesRemove.cart.checkoutUrl,
    cost: data.cartLinesRemove.cart.cost,
  } as Cart;
}

export async function addLineItemsToCart(
  client: StorefrontApiClient,
  cartId: string,
  lines: LineItemInput[]
): Promise<Cart> {
  //map line items to the correct format
  const linesForQuery = lines.map((line) => {
    return {
      merchandiseId: line.variantId,
      quantity: line.quantity,
      attributes: line.attributes,
    };
  });

  const { data, errors } = await client.request(addLineItemsMutation, {
    variables: { cartId, lines: linesForQuery },
  });
  console.log("add line items res", data, errors);

  return {
    id: data.cartLinesAdd.cart.id,
    lineItems: mapCartLines(data.cartLinesAdd.cart.lines),
    checkoutUrl: data.cartLinesAdd.cart.checkoutUrl,
    cost: data.cartLinesAdd.cart.cost,
  } as Cart;
}

export async function updateLineItemsInCart(
  client: StorefrontApiClient,
  cartId: string,
  lines: LineItemUpdate[]
) {
  console.log("update line items", lines);

  const { data, errors } = await client.request(lineItemsUpdateMutation, {
    variables: { cartId, lineItems: lines },
  });
  console.log("update line items res", data, errors);
  return {
    id: data.cartLinesUpdate.cart.id,
    lineItems: mapCartLines(data.cartLinesUpdate.cart.lines),
    checkoutUrl: data.cartLinesUpdate.cart.checkoutUrl,
    cost: data.cartLinesUpdate.cart.cost,
  } as Cart;
}

export async function getProduct(
  client: StorefrontApiClient,
  productId: string
) {
  const { data, errors } = await client.request(selectProductQuery, {
    variables: { productId },
  });

  return data.product;
}

export type CartLines = {
  edges: {
    node: {
      id: string;
      quantity: number;
      cost: {
        subtotalAmount: { amount: number };
        totalAmount: { amount: number };
        amountPerQuantity: { amount: number };
        compareAtAmountPerQuantity: { amount: number; currencyCode: string };
      };
      merchandise: {
        id: string;
        title: string;
        product: { title: string };
        price: { amount: number; currencyCode: string };
        image: {
          altText: string;
          height: number;
          id: string;
          width: number;
          url: string;
        };
      };
      discountAllocations: {
        discountedAmount: { amount: number };
        title: string;
      }[];
    };
  }[];
};

export function mapCartLines(lines: CartLines) {
  return lines.edges.map((line) => {
    return {
      id: line.node.id,
      title: line.node.merchandise.title,
      productTitle: line.node.merchandise.product.title,
      quantity: line.node.quantity,
      variantId: line.node.merchandise.id,
      variantTitle: line.node.merchandise.title,
      price: line.node.merchandise.price,
      cost: line.node.cost,
      image: line.node.merchandise.image,
      discountAllocations: line.node.discountAllocations,
    };
  });
}

/**
 * Returns the variant of a product corresponding to the options given.
 *
 * @example
 * const selectedVariant = client.product.helpers.variantForOptions(product, {
 *   size: "Small",
 *   color: "Red"
 * });
 *
 * @memberof ProductHelpers
 * @method variantForOptions
 * @param {GraphModel} product The product to find the variant on. Must include `variants`.
 * @param {Object} options An object containing the options for the variant.
 * @return {GraphModel} The variant corresponding to the options given.
 */
export function variantForOptions(product, options) {
  return product.variants.find((variant) => {
    return variant.selectedOptions.every((selectedOption) => {
      return options[selectedOption.name] === selectedOption.value.valueOf();
    });
  });
}
