import { roboto } from "@theme-ui/presets";
const typography = {
  fontWeights: {
    ...roboto.fontWeights,
    thin: 300,
  },
  styles: {
    ...roboto.styles,
    a: {
      color: "text",
    },
    h1: {
      ...roboto.styles.h1,
      variant: "text.headingDefault",
      color: "primary",
    },
    h2: {
      ...roboto.styles.h2,
      variant: "text.headingDefault",
      color: "primary",
    },
    h3: {
      ...roboto.styles.h3,
      variant: "text.headingDefault",
      color: "primary",
    },
    h4: {
      ...roboto.styles.h3,
      variant: "text.headingDefault",
      color: "text",
    },
    p: {
      ...roboto.styles.p,
      //mb: 3,
      marginBlockEnd: 3,
    },

    details: {
      mb: 3,
    },
    summary: {
      listStyle: "none",
    },
    ul: {
      listStyle: "disc",
      listStylePosition: "unset",
      ul: {
        listStyle: "circle",
        listStylePosition: "unset",
        ml: "1.5rem",
      },
    },
    li: {
      listStylePosition: "unset",
      p: {
        display: "inline",
      },
    },
  },
  text: {
    default: {
      color: "text",
      fontFamily: "body",
      fontWeight: "light",
      lineHeight: "body",
      //fontSize: 2,
      textTransform: "capitalize",

      p: {
        marginBlockStart: "0px",
        marginBlockEnd: "1em",
        //lineHeight: "1.25em",
      },
    },
    italic: {
      fontStyle: "italic",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
    bold: {
      fontWeight: "bold",
    },

    sectionHeading: {
      m: 0,
      color: "primary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "bold",
      letterSpacing: 1.5,
      marginBlockStart: ".5em",

      fontSize: [5],
    },

    headingDefault: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",

      my: 2,
      textTransform: "capitalize",
    },

    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: 5,
      mb: 2,
      filter: {
        textTransform: "uppercase",
        fontWeight: "bold",
      },
      product: {
        fontSize: [5],
        small: {
          fontSize: [2],
          width: "100%",
        },
      },
    },
  },
};

export default typography;
