import * as React from "react";

import { createStorefrontApiClient } from "@shopify/storefront-api-client";
import {
  addLineItemsToCart,
  Cart,
  createCart,
  getCart,
  removeLineItemsFromCart,
  updateLineItemsInCart,
} from "./storefront-helpers";
/* const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
); */

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  apiVersion: "2025-01",
});

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  didJustAddToCart: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: (variantId: string, quantity: string) => {},
  removeLineItem: (checkoutID: string, lineItemID: string) => {},
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    quantity: string,
    merchandiseId: string
  ) => {},
  client,
  checkout: {
    id: "",
    lineItems: [],
    checkoutUrl: "",
    cost: {
      subtotalAmount: {
        amount: 0,
        currencyCode: "AUD",
      },
      checkoutChargeAmount: {
        amount: 0,
        currencyCode: "AUD",
      },
      totalAmount: {
        amount: 0,
        currencyCode: "AUD",
      },
    },
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState<Cart>(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;
      console.log("existingCheckoutID", existingCheckoutID);
      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await getCart(client, existingCheckoutID);
          /* const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          ); */
          if (existingCheckout) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await createCart(client);
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    addLineItemsToCart(client, checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res);
      setLoading(false);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });

    /*   return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      }); */
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return removeLineItemsFromCart(client, checkoutID, [lineItemID]).then(
      (res) => {
        setCheckout(res);
        setLoading(false);
      }
    );
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [
      {
        id: lineItemID as string,
        quantity: parseInt(quantity, 10),
      },
    ];

    return updateLineItemsInCart(client, checkoutID, lineItemsToUpdate).then(
      (res) => {
        console.log("updateLineItem", res);
        setCheckout(res);
        setLoading(false);
      }
    );
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
